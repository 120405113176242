@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100&family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');
body {
  font-family: "Archivo", sans-serif;
}

select {
  appearance: none;

  position: relative;

  cursor: pointer;
}

.overlay {
  @apply fixed flex items-center justify-center inset-0;
  background: #061a1499;
  transition: 300ms opacity ease-in-out;
  z-index: 50;
}

.close {
  @apply absolute top-[-10px] right-[-10px];
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  height: 100%;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(0.5);
  transition: all 300ms ease-in-out;
  position: absolute;
  inset: 40;
}

.ReactModal__Content--after-open {
  transform: scale(1);
  opacity: 1;
  overflow-y: auto;
  max-height: 80vh;
  overflow-y: scroll;
}

.ReactModal__Content--before-close {
  transform: scale(0.5);
  opacity: 0;
}
